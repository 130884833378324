<template>
  <hero-section />

  <about-us />

  <div class="boxes container" id="ourwork" >
    <h1 style="margin: 0">Our work so far:</h1>
    <WebsiteBox v-for="web in websites" :key="web" :website="web" />
  </div>

  <contact-us />
</template>

<script>
import WebsiteBox from "@/components/WebsiteBox.vue";
import AboutUs from "@/components/AboutUs.vue";
import ContactUs from "@/components/ContactUs.vue";
import HeroSection from "@/components/HeroSection.vue";
// @ is an alias to /src
export default {
  name: "HomeView",
  components: {
    WebsiteBox,
    AboutUs,
    ContactUs,
    HeroSection,
  },
  setup() {
    let websites = [
      {
        title: "Futbol11",
        link: "https://futbol-11.com/",
        image: "https://i.ibb.co/strKVdY/1.webp",
        fb: "https://www.facebook.com/futbol11game/",
        insta: "https://www.instagram.com/futbol11_official/",
        x: "https://x.com/Futbol11game",
        youtube: "https://www.youtube.com/@futbol11daily",
        tiktok: "https://www.tiktok.com/@futbol11daily",
        description:
        "Futbol11 launched in 2022 as a simple daily game and quickly gained popularity. Thanks to its amazing community of players, Futbol11 now offers 15 different game modes and, by August 2024, had made it into the top 5,000 most visited websites worldwide."
        // "Futbol11 started in 2022 as a simple daily game and quickly gained popularity. By the end of that year, two more game modes were added. With a growing, & loyal user base, by August 2024, Futbol11 ranked among the top 5,000 most visited websites worldwide."
      },
      {
        title: "Basketball5",
        link: "https://basketball-5.com/",
        image: "https://i.ibb.co/s23vcfk/2.webp",
        insta: "https://www.instagram.com/basketball5_official/",
        x: "https://x.com/Basketball5game",
        tiktok: "https://www.tiktok.com/@basketball5daily",
        description:
        "Basketball5 is our second project, building on the success of Futbol11. It currently features two game modes, with many more in development."
          // "Basketball5 is our second website ipsum. Lorem ipsum dolor sit amet, consectetuer adipiscing elit, sed diam nonummy nibh euismod tincidunt ut laoreet dolore magna aliquam erat volutpat.",
      },
    ];

    return {
      websites,
    };
  },
};
</script>

<style scoped>
.boxes {
  display: flex;
  flex-direction: column;
 
}

</style>
