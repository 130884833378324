<template>
  <div class="container">
    <!-- <h1>About us:</h1>
    <p>
      <strong style="font-size: 18px; color: var(--red-color)">
        Bergagi
      </strong> was founded in 2024, but our journey began in 2022 with the launch of Futbol11. Created by Zgjim G., Bergagi's CEO, Futbol11 quickly gained a large and active user base from the start.
    </p> -->
    <h1>About Us:</h1>
    <!-- <h4 style="font-style: italic; font-weight: 300; margin-top: -15px;">In the words of Bergagi's Founder, Zgjim G.</h4> -->
    <p>
      <!-- <strong style="font-size: 18px; color: var(--red-color)">
        Bergagi
      </strong>
      was founded in 2024, but our journey began in 2022 with the launch of
      Futbol11. At first, Futbol11 was created by Zgjimi (Bergagi's CEO),
      Futbol11 quickly gained a large and active user base from the start. -->
      <!-- <strong style="font-size: 18px;color: var(--red-color);">Bergagi</strong> was founded in 2024, but our journey really began back in 2022 with the launch of Futbol11, a daily football game that quickly captured the attention of a growing community. Originally starting with just one game mode, Futbol11 expanded later that year with two additional modes, setting the stage for even bigger growth.
<br><br>
The platform's popularity skyrocketed over the next two years. In January 2023 the page had an average of 6,000 daily users. By September 2024, Futbol11 was ranked among the top 5,000 most visited websites in the world.
<br><br>

Futbol11 also has a strong presence on social media, with over 47,000 followers on Instagram, 43,000 on TikTok, 8,000 on Twitter/X, and more than 5,000 subscribers on YouTube.
<br><br>

Riding on this wave of success, we introduced Basketball5, a game that currently offers two modes, with plenty more in development. And that's just the beginning—there are even more exciting projects on the way that we can’t wait to share. Stay tuned for what's coming next!
 -->

 Bergagi was founded in 2024, but our journey began in 2022 with the launch of Futbol11, a daily football game that quickly gained a loyal and active community. Starting with just one game mode, Futbol11 soon expanded to include two more, and its popularity skyrocketed. By January 2023, the platform was attracting 6,000 daily users, and by September 2024, it had become one of the top 5,000 most visited websites worldwide.
<br><br>
Our community continues to grow, with over 47,000 Instagram followers, 43,000 on TikTok, 8,000 on Twitter/X, and more than 5,000 YouTube subscribers. Riding on this success, we introduced Basketball5, a game with two modes and more in development.
<br><br>
Stay tuned as we continue to create exciting new projects for our players!





    </p>
  </div>
</template>

<script>
export default {};
</script>

<style scoped>
p {
  padding: 0 100px;
}

.container {
  margin: 100px auto;
}

@media (max-width: 768px) {
  p {
    padding: unset;
  }
  .container {
    margin: 30px auto;
  }
}
</style>