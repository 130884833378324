<template>
  <div class="div">
    <div class="header">
      <div class="logoDiv">
        <img
          src="https://i.ibb.co/fXsYLpv/cc.png"
          alt=""
        />
        <p>Bergagi</p>
      </div>
      <div class="otherDivs">
        <p>Home</p>
        <p>About Us</p>
        <p>News</p>
      </div>
    </div>
  </div>
</template>

<script>
export default {};
</script>

<style scoped>
.div {
  display: flex;
  justify-content: center;
  background-color: var(--primary-color);
  border-bottom: 1px solid var(--primary2-color);
}
.header {
  background-color: var(--primary-color);
  color: var(--text-color);
  display: flex;
  justify-content: space-between;
  padding: 20px 0;
  font-size: 30px;
  font-weight: 400;
  text-decoration: none;
  width: 1080px;
  max-width: 90vw;
  text-transform: uppercase;
}

.header p {
  margin: 0 5px;
  transition: color 0.3s ease, background-color 0.3s ease;
  padding: 0 10px;
  color: white;
  color: var(--primary2-color);
  font-weight: 600;
}

.header p:hover {
  color: white;
  cursor: pointer;
  background-color: var(--secondary-color);
}

.logoDiv {
  display: flex;
  align-items: center;
  justify-content: space-evenly;
}

.logoDiv img {
  max-height: 40px;

}

.otherDivs {
  display: flex;
  flex-direction: row;
  justify-content: space-around;
  align-items: center;
}
</style>