<template>
  <div class="container">
    <h1>Contact us:</h1>
    <div>
      <p>
        You can reach out to us at our email address:
        <strong style="font-size: 18px; color: var(--red-color)">
          info@bergagi.com
        </strong>
      </p><br>
      <p>We are based in the Republic of Kosovo</p> 
    </div>
  </div>
</template>

<script>
export default {};
</script>

<style scoped>
</style>