<template>
  <div class="heroSection">
    <div class="container specialBox header" style="margin: auto;">
      <div class="leftSide">
        <img src="@/assets/logo.svg" alt="" />
        <p>BERGAGI</p>
      </div>
      <div class="rightSide onlyDesktop" >
        <p>About Us</p>
        <p>Portfolio</p>
        <p>Contact</p>
      </div>
    </div>

    <div class="container secondSection" style="margin: auto;">
      <div class="leftSide" style="position: relative;">
        
          <img src="@/assets/asset.png" style="height: 120px; position: absolute; right: 0; bottom: 0px;" class="onlyMobile" alt="" />
        <!-- <h1>Building websites, applications</h1> -->
        <h1>Transforming passion projects into reality</h1>
        <!-- <p>Transforming passion projects into reality</p> -->
        <p>Crafting original websites for thousands of daily users</p>
        <button class="specialBox"  @click="scrollToWork">See our work</button>  
      </div>
      <div class="rightSide">
        <!-- <img style="margin-left: -50px;" src="@/assets/Hero.svg" alt=""> -->
        <img src="@/assets/asset.png" class="onlyDesktop" alt="" />
      </div>
    </div>
  </div>
</template>

<script>
export default {
  methods: {
    scrollToWork() {
      document.getElementById('ourwork').scrollIntoView({ behavior: 'smooth' });
    },
  },
};
</script>

<style scoped>
.heroSection {
  background-color: var(--blue-color);
  padding: 20px;
  color: white;
}

.header {
  background-color: var(--bluelight-color);
  display: flex;
  justify-content: space-between;
  padding: 20px;
  font-weight: 600;
  font-size: 18px;
  align-items: center;
  margin-bottom: 60px;
}

.header .leftSide {
  display: flex;
  align-items: center;
  cursor: pointer;
}

.header .leftSide img {
  height: 36px;
  margin-right: 12px;
}

.header .leftSide:hover p {
  color: var(--red-color);
}

.header .leftSide p {
  color: white;
  font-size: 22px;
  letter-spacing: 4px;
}

.header .rightSide {
  display: flex;
  align-items: center;
  justify-content: space-between;
}

.header .rightSide p {
  color: white;
  cursor: pointer;
  /* margin: 0 5px; */
  border-radius: 50px 50px 0 50px;
  padding: 5px 15px;
}

.header .rightSide p:hover {
  background-color: var(--blue-color);
}
.header .rightSide p:last-child {
  margin-right: 0px;
  margin-left: 10px;
  background-color: var(--red-color);
  color: var(--blue-color);
}

.secondSection {
  height: 500px;
  display: flex;
  align-items: center;
  justify-content: space-between;
}

.secondSection .leftSide {
  /* width: 800px; */
  text-align: left;
  max-width: 50%;
}
.secondSection .leftSide h1 {
  font-size: 45px;
}
.secondSection .leftSide p {
  color: white;
  margin-bottom: 40px;
}
.secondSection .leftSide .specialBox {
  padding: 10px 20px;
  border: 2px solid var(--red-color);
  cursor: pointer;
  display: inline-block;
  font-weight: inherit;
    background-color: inherit;
    color: inherit;
    font-family: inherit; font-size: inherit;
}

.secondSection .leftSide .specialBox:hover {
  background-color: var(--red-color);
}

.secondSection .rightSide {
  /* background-color: var(--bluelight-color); */
  height: 80%;
  /* width: 280px; */
  overflow: hidden;
}

.secondSection .rightSide img {
  height: 100%;
  /* margin-left: -50px; */
}

@media (max-width: 768px) {
.heroSection{
    padding:  0;
}

.header{
    border-radius: 0;
    max-width: unset;
    width: unset;
    display: flex;
    justify-content: center; 
    margin-bottom: unset;
}

.header .leftSide p{
    font-size: 28px;
}

.secondSection{
    align-items: flex-start;
    padding: 40px 0;
    height: unset;
    /* height: 220px; */
}
.secondSection .leftSide{
    max-width: unset;
}
.secondSection .leftSide h1{
    font-size: 30px;
    margin: 0 0 40px 0;
}

.secondSection .leftSide p{
    max-width: 60%;
    margin-bottom: 25px;
}

.secondSection .rightSide img{
    height: 100px;
}

.secondSection .leftSide .specialBox:hover {
    background-color: unset;
}
}
</style>