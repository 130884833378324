<template>
  <div class="backgroundDiv">
    <div class="container" style="margin: auto">
      <div class="footer">
        <h2 style="margin: 0">Social Media</h2>
        <div class="links">
          <a href="https://www.instagram.com/bergagi.official/" target="_blank">
            <i class="bi bi-instagram"></i>
          </a>
          <a href="https://x.com/BergagiOfficial" target="_blank">
            <i class="bi bi-twitter-x"></i>
          </a>
          <a href="https://www.facebook.com/bergagi.official/" target="_blank">
            <i class="bi bi-facebook"></i>
          </a>
          <i class="bi bi-linkedin"></i>
        </div>
        <p style="color: white; font-weight: 200">©Bergagi Group LLC</p>
      </div>
    </div>
  </div>
</template>

<script>
export default {};
</script>

<style scoped>
.backgroundDiv {
  background-color: var(--blue-color);
  position: sticky;
  width: 100%;
  /* position: sticky; */
  top: 100%;
}

.container {
  padding: 70px 0;
  display: flex;
  justify-content: space-evenly;
}

.footer .links {
  display: flex;
  align-items: center;
  height: 70px;
  margin: 10px 0;
}

.footer h2 {
  color: var(--red-color);
}

.footer i {
  color: white;
  font-size: 25px;
  cursor: pointer;
  margin: 10px;
  transition: 0.3s;
}
.footer i:hover {
  color: var(--red-color);
  font-size: 35px;
}

@media (max-width: 768px) {
  .container {
    padding: 50px 0;
  }
}
</style>