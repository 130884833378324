<template>
  <!-- <Header /> -->
  <!-- <nav>
    <router-link to="/">Home</router-link> |
    <router-link to="/about">About</router-link>
  </nav> -->
  <router-view />
  <Footer />
</template>

<script>
import Header from "@/components/Header.vue";
import Footer from "./components/Footer.vue";
// @ is an alias to /src

export default {
  name: "HomeView",
  components: {
    Header,
    Footer,
  },
};
</script>


<style>
@import url("https://fonts.googleapis.com/css2?family=SUSE:wght@100..800&display=swap");
@import url("https://fonts.googleapis.com/css2?family=Roboto+Mono:ital,wght@0,100..700;1,100..700&display=swap");
@import url("https://fonts.googleapis.com/css2?family=Poppins:ital,wght@0,100;0,200;0,300;0,400;0,500;0,600;0,700;0,800;0,900;1,100;1,200;1,300;1,400;1,500;1,600;1,700;1,800;1,900&display=swap");
@import url("https://fonts.googleapis.com/css2?family=Exo+2:ital,wght@0,100..900;1,100..900&display=swap");
@import url("https://fonts.googleapis.com/css2?family=Lexend:wght@100..900&display=swap");

#app {
  font-family: Avenir, Helvetica, Arial, sans-serif;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
  text-align: center;
  color: #2c3e50;
  font-family: "SUSE", sans-serif;
  /* font-family: "Roboto Mono", monospace; */
  /* font-family: "Poppins", sans-serif; */
  /* font-family: "Exo 2", sans-serif; */
  min-height: 100vh;
  font-family: "Lexend", sans-serif;
}

body {
  margin: 0;
  background-color: var(--background-color);
}

p {
  margin: 0;
  color: var(--text-color);
  /* color: white; */
}

.container {
  width: 1080px;
  max-width: 90vw;
  /* background-color: red; */
  margin: 130px auto;
}

.specialBox {
  border-radius: 50px 50px 0 50px;
  /* padding: 20px; */
}

:root {
  /* --primary-color: rgb(31, 31, 31); */
  /* --text-color: rgb(255, 255, 255); */

  /* --background-color: #130922;
  --primary-color: #311952;
  --secondary-color: #531F66;
  --text-color: #D6BCED; */

  --background-color: #f5f5f5;
  --primary-color: #1d1d1d;
  --primary2-color: #f0ec02;
  --secondary-color: #1259aa;
  --text-color: #1d1d1d;

  --red-color: #ff3159;
  --blue-color: #060b35;
  --bluelight-color: #282c50;

  /* --background-color: #150940;
    --primary-color: #400FF2;
    --secondary-color: #30168C;
    --text-color: #D2F25C; */
}

nav {
  padding: 30px;
}

nav a {
  font-weight: bold;
  color: #2c3e50;
}

nav a.router-link-exact-active {
  color: #42b983;
}

.slide-in-bottom {
  -webkit-animation: slide-in-bottom 1s cubic-bezier(0.25, 0.46, 0.45, 0.94)
    both;
  animation: slide-in-bottom 1s cubic-bezier(0.25, 0.46, 0.45, 0.94) both;
}

@-webkit-keyframes slide-in-bottom {
  0% {
    -webkit-transform: translateY(1000px);
    transform: translateY(1000px);
    opacity: 0;
  }
  100% {
    -webkit-transform: translateY(0);
    transform: translateY(0);
    opacity: 1;
  }
}
@keyframes slide-in-bottom {
  0% {
    -webkit-transform: translateY(1000px);
    transform: translateY(1000px);
    opacity: 0;
  }
  100% {
    -webkit-transform: translateY(0);
    transform: translateY(0);
    opacity: 1;
  }
}

@media (max-width: 768px) {
  .onlyDesktop {
    display: none !important;
  }

  .container{
    margin: 30px auto;
  }
}

@media (min-width: 769px) {
  .onlyMobile {
    display: none !important;
  }
}
</style>
