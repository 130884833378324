<template>
  <div ref="box" :class="{ box: true, 'animate__animated ': isVisible }">
    <div class="boxContent">
      <h1 style="letter-spacing: 5px" class="onlyMobile">
        {{ website.title }}
      </h1>
      <img class="specialBox" :src="website.image" alt="" />
      <div class="textDiv">
        <h1 style="letter-spacing: 5px" class="onlyDesktop">
          {{ website.title }}
        </h1>
        <p style="font-weight: 300">{{ website.description }}</p>
        <h3 st style="margin: 15px 0 0 0">
          Socials:
          <span class="socialLinks">
            <a v-if="website.insta" :href="website.insta" target="_blank">
              <i class="bi bi-instagram"></i>
            </a>
            <a v-if="website.tiktok" :href="website.tiktok" target="_blank">
              <i class="bi bi-tiktok"></i>
            </a>
            <a v-if="website.x" :href="website.x" target="_blank">
              <i  class="bi bi-twitter-x"></i>
            </a>

            <a v-if="website.youtube" :href="website.youtube" target="_blank">
              <i class="bi bi-youtube"></i>
            </a>

            <a v-if="website.fb" :href="website.fb  " target="_blank">
              <i  class="bi bi-facebook"></i>
            </a>
          </span>
        </h3>
        <a :href="website.link" target="_blank">
          <button>Go to website</button>
        </a>
      </div>
    </div>
  </div>
</template>

<script>
export default {
  props: {
    website: {
      type: Object,
      required: true,
    },
  },
  data() {
    return {
      isVisible: false, // This will track if the element is visible
    };
  },
  mounted() {
    const observer = new IntersectionObserver(
      (entries) => {
        entries.forEach((entry) => {
          if (entry.isIntersecting) {
            this.isVisible = true; // Add the class when the element is visible
            observer.unobserve(this.$refs.box); // Stop observing once the component is in view
          }
        });
      },
      {
        threshold: 0.1, // Trigger when 10% of the element is visible
      }
    );

    observer.observe(this.$refs.box); // Start observing the component
  },
};
</script>

<style scoped>
.box {
  /* background-color: black; */
  /* border-radius: 20px 20px 0 20px; */
  margin: 35px 0;
  /* padding: 25px;  */
}

.box h1 {
  color: var(--red-color);
  text-align: left;
  margin: 0;
  margin-bottom: 10px;
  text-transform: uppercase;
}

.boxContent {
  display: flex;
}
.boxContent img {
  height: 250px;
  margin-right: 35px;
  outline: 1px solid var(--blue-color);
  outline-offset: 3px;
  /* border: 3px solid var(--red-color); */
  /* box-shadow: inset 0 0 0 1px var(--blue-color); */

  /* box-shadow: 5px 5px 10px 2px rgba(0, 0, 0, 0.5); */

  /* border: 1px solid var(--secondary-color); */
  /* border-radius: 20px; */
}

.textDiv {
  text-align: left;
}

.textDiv button {
  margin: 25px 0;
  font-size: 16px;
  font-family: inherit;
  padding: 10px 15px;
  border: unset;
  /* border: 2px solid var(--secondary-color); */
  /* box-shadow: inset 0 0 0 1px var(--blue-color); */
  border-radius: 20px 20px 0 20px;
  background-color: var(--blue-color);
  color: white;
  cursor: pointer;
}

.textDiv button:hover {
  background-color: var(--red-color);
  color: var(--blue-color);
}

.textDiv h3 {
  /* height: 35px; */
  display: flex;
  align-items: center;
}

.socialLinks {
  cursor: pointer;
  height: 30px;
  display: flex;
  align-items: center;
  margin: 0 10px;
}

.socialLinks i {
  color: var(--blue-color);
  font-size: 24px;
  transition: 0.3s;
  margin: 0 10px;
}

.socialLinks i:hover {
  color: var(--red-color);
  font-size: 32px;
}

@media (max-width: 768px) {
  .boxContent {
    flex-direction: column;
    align-items: center;
    /* justify-content: space-around; */
  }
  .boxContent img {
    /* max-width: 95vw; */
    max-width: calc(100vw - 40px);
    margin: 20px 0;
    object-fit: cover;
    /* height: 200px; */
  }

  .textDiv {
    text-align: center;
    display: flex;
    flex-direction: column;
    align-items: center;
  }
}
</style>